@charset "UTF-8";
/* menu */
/* helper vars, mixins etc. */
.clearfix {
  clear: both;
  display: block;
  height: 0;
  width: 100%; }

.hidden {
  display: none; }

.float-left {
  float: left; }

.float-right {
  float: right; }

.margin-auto {
  margin-left: auto;
  margin-right: auto; }

@keyframes anim {
  0% {
    display: none;
    opacity: 0; }
  1% {
    display: block;
    opacity: 0;
    transform: scale(0); }
  100% {
    opacity: 1;
    transform: scale(1); } }

nav.navigation {
  background: #efefef;
  min-height: 70px;
  position: relative;
  width: 100%;
  z-index: 100; }
  nav.navigation .sm-nav {
    background: #4b545f;
    display: block;
    padding: 15px;
    text-align: right;
    width: 100%; }
    @media (min-width: 992px) {
      nav.navigation .sm-nav {
        display: none; } }
    nav.navigation .sm-nav .sm-menu,
    nav.navigation .sm-nav .sm-search,
    nav.navigation .sm-nav .sm-menu-close {
      background: transparent;
      border: none;
      color: #fff;
      display: inline-block;
      margin-left: 15px;
      text-align: right; }
      nav.navigation .sm-nav .sm-menu:hover,
      nav.navigation .sm-nav .sm-search:hover,
      nav.navigation .sm-nav .sm-menu-close:hover {
        text-shadow: 0 0 5px rgba(0, 0, 0, 0.5); }
  nav.navigation ul {
    list-style: none;
    padding: 0 20px;
    position: relative;
    /* &:first-child {
			background: $bg-color-primary;
			width: 100%;
		} */ }
    @media (max-width: 991px) {
      nav.navigation ul {
        display: none;
        padding: 0;
        width: 100%; } }
    nav.navigation ul.menubar {
      float: left;
      margin: 0; }
      @media (max-width: 991px) {
        nav.navigation ul.menubar {
          display: none;
          float: none; } }
    nav.navigation ul.userbar {
      float: right;
      padding: 0; }
      @media (max-width: 991px) {
        nav.navigation ul.userbar {
          float: none; } }
      nav.navigation ul.userbar ul {
        margin-left: -50%;
        width: 150%; }
        @media (max-width: 991px) {
          nav.navigation ul.userbar ul {
            margin-left: 0;
            width: 100%; } }
    nav.navigation ul:after {
      clear: both;
      content: "";
      display: block; }
    nav.navigation ul li {
      float: left;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      transition: all 0.3s ease; }
      @media (max-width: 991px) {
        nav.navigation ul li {
          float: none; } }
      nav.navigation ul li .sm-nav-index {
        display: none; }
        @media (min-width: 768px) and (max-width: 991px) {
          nav.navigation ul li .sm-nav-index {
            display: block; } }
        @media (min-width: 0) and (max-width: 767px) {
          nav.navigation ul li .sm-nav-index {
            display: block; } }
      nav.navigation ul li .sm-nav-back {
        display: none; }
        @media (min-width: 768px) and (max-width: 991px) {
          nav.navigation ul li .sm-nav-back {
            display: block; } }
      nav.navigation ul li a {
        color: #757575;
        display: block;
        padding: 25px 40px;
        text-decoration: none; }
        nav.navigation ul li a i.btn-down {
          display: none; }
          @media (min-width: 992px) {
            nav.navigation ul li a i.btn-down {
              background: #4b545f;
              color: #fff;
              display: inline-flex;
              height: 90%;
              opacity: 0.08;
              padding: 0 10px 0 10px;
              position: absolute;
              top: 5%;
              align-items: center; }
              nav.navigation ul li a i.btn-down:after {
                content: '▼'; } }
      nav.navigation ul li:hover {
        background: #4b545f; }
        nav.navigation ul li:hover > a {
          color: #fff; }
          nav.navigation ul li:hover > a i.btn-down {
            display: none; }
            nav.navigation ul li:hover > a i.btn-down:after {
              content: '▲'; }
        @media (min-width: 992px) {
          nav.navigation ul li:hover > ul {
            display: block; } }
    nav.navigation ul ul {
      animation: anim .3s ease;
      background: #5f6975;
      box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.15);
      display: none;
      padding: 0;
      position: absolute;
      top: 100%; }
      @media (max-width: 991px) {
        nav.navigation ul ul {
          position: relative; } }
      nav.navigation ul ul li {
        border-bottom: 1px solid #575f6a;
        border-top: 1px solid #6b727c;
        float: none;
        position: relative; }
        nav.navigation ul ul li a {
          color: #fff;
          padding: 15px 40px; }
        nav.navigation ul ul li ul {
          left: 100%;
          top: 0; }
          @media (max-width: 991px) {
            nav.navigation ul ul li ul {
              left: 0;
              position: relative; } }

.fast-search-mobile {
  display: none; }

.fast-search-desktop {
  display: block; }
