/* menu v2 */


// imports
@import '../../helper';

// vars
$bg-color-primary: #efefef;
$bg-color-secondary: #5f6975;
$bg-color-test: #78B63D;
$bg-color-hover: #4b545f;
$a-color: #757575;
$a-color-hover: #fff;


// keyframes
@keyframes anim {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@-webkit-keyframes anim {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

//styles
nav.navigation-v2 {
	background: $bg-color-primary;
	min-height: 70px;
	position: relative;
	width: 100%;
	z-index: 100;
	.sm-nav {
		background: $bg-color-hover;
		display: block;
		padding: 15px 5px;
		text-align: right;
		width: 100%;
		z-index: 100;

		@include desktop {
			display: none;
		}
		a.sm-brand {
			color: $a-color-hover;
			float: left;
			font-size: 0.7em;

			@include desktop {
				display: none;
			}
			&:hover {
				text-decoration: none;
				text-shadow: 0 0 5px rgba(0,0,0,.5);
			}
			h1 {
				margin: 0;
			}
		}
		.sm-menu,
		.sm-menu-close,
		.sm-menu-back,
		.sm-user,
		.sm-search {
			background: transparent;
			border: none;
			color: $a-color-hover;
			display: inline-block;
			margin-right: 10px;
			text-align: right;
			&:hover {
				text-shadow: 0 0 5px rgba(0,0,0,.5);
			}
		}
	}
	.sm-nav-close {
		padding-bottom: 20px;
		@media #{$lg-min} {
			display: none;
		}
		.sm-menu-close {
			color: #fff;
		}
	}
	ul {
		list-style: none;
		/* &:first-child {
			background: $bg-color-primary;
			width: 100%;
		} */
		margin: 0;
		padding: 0 20px;
		position: relative;

		@include desktop {
			float: left;
		}
		@include tablet {
			background: $bg-color-hover;
			box-shadow: -2px 2px 2px rgba(0,0,0,0.2);
			display: none;
			float: none;
			padding: 0;
			position: absolute;
			right: 0;
			top: 0;
			width: 270px;
			z-index: 200;
		}
		@include mobile {
			background: $bg-color-hover;
			box-shadow: -2px 2px 2px rgba(0,0,0,0.2);
			display: none;
			float: none;
			padding: 0;
			position: absolute;
			right: 0;
			top: 0;
			width: 270px;
			z-index: 200;
		}
		&.userbar {
			@include desktop {
				float: right;
				padding: 0;
				li ul {
					margin-left: -50%;	
					position: absolute;
					width: 150%;
				}
			}
		}
		&:after {
			clear: both; 
			content: ""; 
			display: block;
		}
		li {
			float: left;

			@include desktop {
				@include animation(all, .3s, ease);
			}
			@media #{$sm-max} {
				float: none;
			}
			.sm-nav-index {
				display: none;

				@include tablet {
					display: block;
				}
				@include mobile {
					display: block;
				}
			}
			.sm-nav-back {
				display: none;

				@include tablet {
					display: block;
				}
			}
			a {
				color: $a-color;
				display: block; 
				padding: 25px 40px;
				text-decoration: none;

				@include tablet {
					color: $a-color-hover;
				}
				@include mobile {
					color: $a-color-hover;
				}
			}
			&:hover {
				background: $bg-color-hover;

				@include tablet {
					background: $bg-color-primary;
				}
				@include mobile {
					background: $bg-color-primary;
				}
				&.sm-nav {
					background: transparent;
				}
				> a {
					color: $a-color-hover;

					@include tablet {
						color: $a-color;
					}
					@include mobile {
						color: $a-color;
					}
				}
				> ul {
					@include desktop {
						display: block;
					}
				}
			}
		}
		// submenus
		ul {
			background: $bg-color-secondary; 
			box-shadow: 0px 0px 9px rgba(0,0,0,0.15);
			display: none;
			padding: 0;
			position: absolute; 
			top: 0;

			@include desktop {
				animation: anim .3s ease;
				display: none;
				top: 100%;
			}
			@include tablet {
				background: $bg-color-hover; 
				right: 0;
				width: 260px;
			}
			@include mobile {
				background: $bg-color-hover; 
				right: 0;
				width: 260px;
			}
			/*
			&:after {
				border: solid transparent;
                border-color: transparent transparent $bg-color-secondary transparent;
                border-width: 5px;
                bottom: 100%;
                content: " ";
                height: 0;
                left: 25px;
                margin-left: -15px;
                position: absolute;
                width: 0;

                pointer-events: none;
			}
			*/
			li {
				float: none; 

				@include desktop {
					border-bottom: 1px solid #575f6a;
					border-top: 1px solid #6b727c;
				}
				a {
					color: #fff;
					padding: 15px 40px;
				}
				ul {
					top:0;

					@include desktop {
						left: 100%;
						width: 120%;
					}
					@media #{$sm-max} {
						position: absolute;
						right: 0;
						width: 250px;
					}
				}
			}
		}
	}
}