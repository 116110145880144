.recaptcha-box {
    margin: 10px 0;
}

.cart_product {
    margin: 0;
}

.cart_quantity_button a, .cart_quantity_input {
    border: 1px solid #f0f0e9;
    height: 28px;
}

.cart_price {
    display: table-cell;
    vertical-align: middle;
    p {
        margin: 0;
        text-align: right;
    }
}

.cart_total {
    display: table-cell;
    vertical-align: middle;
    p {
        margin: 0;
        text-align: right;
    }
}

.cart_quantity {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    .cart_quantity_button {
        display: inline-block;
        width: auto;
        height: auto;
        text-align: center;
        margin: 0 auto;
    }
}

.cart_delete  {
  display: table-cell;
  overflow: hidden;
  vertical-align: middle;
  text-align: center;
}


.cart_delete a {
  background-color: #AC0000;
  color: #FFFFFF;
  padding: 5px 7px;
  font-size: 16px;
}

.cart_delete a:hover {
  background-color: #F0F0E9;
}

.cart_menu {
    .description {
        text-align: center;
    }
    .price {
        text-align: center;
    }
    .quantity {
        text-align: center;
    }
    .total {
        text-align: center;
    }
}

@media (max-width: 991px) {
    #cart_items .cart_info .cart_description {
        display: table-cell;
        vertical-align: middle;
    }
    #cart_items .cart_info .cart_description p {
        margin: 0;
    }
    .btn-default {
        padding: 0;
    }
}