/* cookie */


// imports
@import '../../helper';


// vars
$bg-color: #EFEFEF;
$text-color: #757575;

$btn-bg-color: #757575;
$btn-text-color: #fff;

$btn-bg-hover-color: #4B545F;
$btn-text-hover-color: #fff;

//styles
.cookie {
	background-color: $bg-color;
	box-shadow: 0 0 5px rgba(0,0,0,.5);
	color: $text-color;
	display: none;
	left: 0;
	line-height: 30px;
	min-height: 50px;
	padding: 10px 50px 10px 50px;
	position: fixed;
	width: 100%;
	z-index: 110;

	@include mobile {
		padding: 10px;
	}
	&.top {
		top: 0;
	}
	&.bottom {
		bottom: 0;
	}
	p {
		display: inline-block;
	}
	.btn {
		background-color: $btn-bg-color;
		border: 0;
		color: $btn-text-color;
		display: inline-block;
		float: right;
		height: 30px;
		padding: 5px 15px;
		&:hover {
			background-color: $btn-bg-hover-color;
			box-shadow: 1px 1px 1px rgba(0,0,0,.25);
			color: $btn-text-hover-color;
		}
	}
}