/* menu */


// imports
@import '../../helper';

// vars
$bg-color-primary: #efefef;
$bg-color-secondary: #5f6975;
$bg-color-hover: #4b545f;
$a-color: #757575;
$a-color-hover: #fff;


// keyframes
@keyframes anim {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

//styles
nav.navigation {
	background: $bg-color-primary;
	min-height: 70px;
	position: relative;
	width: 100%;
	z-index: 100;
	.sm-nav {
		background: $bg-color-hover;
		display: block;
		padding: 15px;
		text-align: right;
		width: 100%;

		@include desktop {
			display: none;
		}
		.sm-menu,
		.sm-search,
		.sm-menu-close {
			background: transparent;
			border: none;
			color: $a-color-hover;
			display: inline-block;
			margin-left: 15px;
			text-align: right;
			&:hover {
				text-shadow: 0 0 5px rgba(0,0,0,.5);
			}
		}
	}
	ul {
		list-style: none;
		padding: 0 20px;
		position: relative;
		@media #{$sm-max} {
			display: none;
			padding: 0;
			width: 100%;
		}
		/* &:first-child {
			background: $bg-color-primary;
			width: 100%;
		} */
		&.menubar {
			float: left;
			margin: 0;
			@media #{$sm-max} {
				display: none;
				float: none;
			}
		}
		&.userbar {
			float: right;
			padding: 0;
			@media #{$sm-max} {
				float: none;
			}
			ul {
				margin-left: -50%;	
				width: 150%;
				@media #{$sm-max} {
					margin-left: 0;
					width: 100%;
				}
			}
		}
		&:after {
			clear: both; 
			content: ""; 
			display: block;
		}
		li {
			float: left;

			@include animation(all, .3s, ease);
			@media #{$sm-max} {
				float: none;
			}
			.sm-nav-index {
				display: none;

				@include tablet {
					display: block;
				}
				@include mobile {
					display: block;
				}
			}
			.sm-nav-back {
				display: none;

				@include tablet {
					display: block;
				}
			}
			a {
				color: $a-color;
				display: block; 
				padding: 25px 40px;
				text-decoration: none;
				i.btn-down {
					display: none;

					@include desktop {
						background: $bg-color-hover;
						color: #fff;
						display: inline-flex;
						height: 90%;
						opacity: 0.08;
						padding: 0 10px 0 10px;
						position: absolute;
						top: 5%;

						align-items: center;
						&:after {
							content: '▼';
						}
					}
				}
			}
			&:hover {
				background: $bg-color-hover;
				> a {
					color: $a-color-hover;
					i.btn-down {
						display: none;
						&:after {
							content: '▲';
						}
					}
				}
				> ul {
					@include desktop {
						display: block;
					}
				}
			}
		}
		// submenus
		ul {
			animation: anim .3s ease;
			background: $bg-color-secondary; 
			box-shadow: 0px 0px 9px rgba(0,0,0,0.15);
			display: none;
			padding: 0;
			position: absolute; 
			top: 100%;
			@media #{$sm-max} {
				position: relative;
			}
			li {
				border-bottom: 1px solid #575f6a;
				border-top: 1px solid #6b727c;
				float: none; 
				position: relative;
				a {
					color: #fff;
					padding: 15px 40px;
				}
				ul {
					left: 100%; 
					top:0;
					@media #{$sm-max} {
						left: 0;
						position: relative;
					}
				}
			}
		}
	}
}

  .fast-search-mobile {
  	display:none;
  }

  .fast-search-desktop {
  	display:block;
  }