/* show */

// imports
@import '../../helper';

// styles
.index {
	.box {
		border-bottom: 1px solid #EFEFEF;
		float: left;
		height:210px;
		margin: 2% 1%;
		overflow: hidden;
		position: relative;
		width: 48%;
		figure {
			float :left;
			padding-right: 10px;
			width: 20%;
			figcaption {
				font-size: 0.7em;
			}
		}
		.right {
			float: left;
			height: 100%;
			width: 80%;
			h3 {
				margin-top: 0;
			}
			.lead {
				font-size: 0.8em;
				height: 60px;
				margin: 0;
				overflow: hidden;
			}
			a {
				bottom: 0;
				position: absolute;
				right: 0;
			}
		}
	}
}
