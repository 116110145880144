@charset "UTF-8";
/*************************
*******Typography******
**************************/
@import url(https://fonts.googleapis.com/css?family=Roboto:400,300,400italic,500,700,100);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,800,300,600,700);
@import url(https://fonts.googleapis.com/css?family=Abel);
header {
  font-family: abel, 'Roboto', sans-serif;
  height: 20em;
  background-image: url(../images/logo-background.jpg);
  background-size: 100% auto;
  background-position: 0 -200px;
  position: relative; }

.header-bottom {
  display: block;
  width: 100%;
  position: absolute;
  bottom: 0; }

.header-bottom .container {
  position: relative;
  margin: 0 auto; }

span.cart-counter {
  color: #AC0000; }

body {
  font-family: 'Roboto', sans-serif;
  position: relative;
  font-weight: 400px; }

ul li {
  list-style: none; }

a:hover {
  outline: none;
  text-decoration: none; }

a:focus {
  outline: none;
  outline-offset: 0; }

a {
  -webkit-transition: 300ms;
  -moz-transition: 300ms;
  -o-transition: 300ms;
  transition: 300ms; }

h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto', sans-serif; }

.btn:hover,
.btn:focus {
  outline: none;
  box-shadow: none; }

.navbar-toggle {
  background-color: #000; }

#logo a {
  color: white;
  font-size: 4em;
  font-family: abel;
  text-shadow: 2px 2px 8px #000000;
  text-decoration: none; }

#logo span {
  color: white;
  font-size: 1.5em;
  font-family: abel;
  text-shadow: 2px 2px 8px #000000;
  display: inline; }

a#scrollUp {
  bottom: 0px;
  right: 10px;
  padding: 5px 10px;
  background: #AC0000;
  color: #FFF;
  -webkit-animation: bounce 2s ease infinite;
  animation: bounce 2s ease infinite; }

a#scrollUp i {
  font-size: 30px; }

/*************************
*******Header CSS******
**************************/
.header_top {
  background: none repeat scroll 0 0 #F0F0E9; }

.contactinfo ul li:first-child {
  margin-left: -15px; }

.contactinfo ul li a {
  font-size: 12px;
  color: #696763;
  font-family: 'Roboto', sans-serif; }

.contactinfo ul li a:hover {
  background: inherit; }

.social-icons ul li a {
  border: 0 none;
  border-radius: 0;
  color: #696763;
  padding: 0px; }

.social-icons ul li {
  display: inline-block; }

.social-icons ul li a i {
  padding: 11px 15px;
  transition: all 0.9s ease 0s;
  -moz-transition: all 0.9s ease 0s;
  -webkit-transition: all 0.9s ease 0s;
  -o-transition: all 0.9s ease 0s; }

.social-icons ul li a i:hover {
  color: #fff;
  transition: all 0.9s ease 0s;
  -moz-transition: all 0.9s ease 0s;
  -webkit-transition: all 0.9s ease 0s;
  -o-transition: all 0.9s ease 0s; }

.fa-facebook:hover {
  background: #0083C9; }

.fa-twitter:hover {
  background: #5BBCEC; }

.fa-linkedin:hover {
  background: #FF4518; }

.fa-dribbble:hover {
  background: #90C9DC; }

.fa-google-plus:hover {
  background: #CE3C2D; }

.header-middle {
  border-bottom: 1px solid #f5f5f5; }

.header-middle .container .row {
  margin-left: 0;
  margin-right: 0;
  padding-bottom: 20px;
  padding-top: 20px; }

.header-middle .container .row .col-sm-4 {
  padding-left: 0; }

.header-middle .container .row .col-sm-8 {
  padding-right: 0; }

.usa {
  border-radius: 0;
  color: #B4B1AB;
  font-size: 12px;
  margin-right: 20px;
  padding: 2px 15px;
  margin-top: 10px; }

.usa:hover {
  background: #AC0000;
  color: #fff;
  border-color: #AC0000; }

.usa:active, .usa.active {
  background: none repeat scroll 0 0 #AC0000;
  box-shadow: inherit;
  outline: 0 none; }

.btn-group.open .dropdown-toggle {
  background: transparent;
  box-shadow: none; }

.dropdown-menu li a:hover, .dropdown-menu li a:focus {
  background-color: #AC0000;
  color: #FFFFFF;
  font-family: 'Roboto', sans-serif;
  text-decoration: none; }

.info-left {
  padding-bottom: 5px; }

.info-left span {
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-size: 1.5em;
  margin-top: 10px;
  padding: 0;
  text-shadow: 1px 1px 2px #000000; }

.shop-menu ul li {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px; }

.shop-menu ul li:last-child {
  padding-right: 0; }

.shop-menu ul li a {
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 700;
  margin-top: 10px;
  padding: 0;
  text-shadow: 1px 1px 2px #000000; }

.shop-menu ul li a i {
  margin-right: 3px; }

.shop-menu ul li a:hover {
  color: #AC0000;
  background: none; }

.header-bottom {
  padding-bottom: 15px; }

.navbar-collapse.collapse {
  padding-left: 0; }

.mainmenu ul li {
  padding-right: 15px;
  padding-left: 15px; }

.mainmenu ul li:first-child {
  padding-left: 0px; }

.mainmenu ul li a {
  color: #696763;
  font-family: 'Roboto', sans-serif;
  font-size: 17px;
  font-weight: 300;
  padding: 0;
  padding-bottom: 10px; }

.mainmenu ul li a:hover, .mainmenu ul li a.active, .shop-menu ul li a.active {
  background: none;
  color: #fdb45e; }

.search_box input {
  background: #F0F0E9;
  border: medium none;
  color: #B2B2B2;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 300;
  height: 35px;
  outline: medium none;
  padding-left: 10px;
  width: 155px;
  background-repeat: no-repeat;
  background-position: 130px; }

/*  Dropdown menu*/
.navbar-header
.navbar-toggle .icon-bar {
  background-color: #fff; }

.nav.navbar-nav > li:hover > ul.sub-menu {
  display: block;
  -webkit-animation: fadeInUp 400ms;
  -moz-animation: fadeInUp 400ms;
  -ms-animation: fadeInUp 400ms;
  -o-animation: fadeInUp 400ms;
  animation: fadeInUp 400ms; }

ul.sub-menu {
  position: absolute;
  top: 30px;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  list-style: none;
  padding: 0;
  margin: 0;
  width: 220px;
  -webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
  display: none;
  z-index: 999; }

.dropdown ul.sub-menu li .active {
  color: #FDB45E;
  padding-left: 0; }

.navbar-nav li ul.sub-menu li {
  padding: 10px 20px 0; }

.navbar-nav li ul.sub-menu li:last-child {
  padding-bottom: 20px; }

.navbar-nav li ul.sub-menu li a {
  color: #fff; }

.navbar-nav li ul.sub-menu li a:hover {
  color: #FDB45E; }

.fa-angle-down {
  padding-left: 5px; }

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

/*************************
*******Footer CSS******
**************************/
#footer {
  background: #F0F0E9; }

.footer-top .container {
  border-bottom: 1px solid #E0E0DA;
  padding-bottom: 20px; }

.companyinfo {
  margin-top: 57px; }

.companyinfo h2 {
  color: #B4B1AB;
  font-family: abel;
  font-size: 27px;
  text-transform: uppercase; }

.companyinfo h2 span {
  color: #AC0000; }

.companyinfo p {
  color: #B3B3AD;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 300; }

.footer-top .col-sm-3 {
  overflow: hidden; }

.video-gallery {
  margin-top: 57px;
  position: inherit; }

.video-gallery a img {
  height: 100%;
  width: 100%; }

.iframe-img {
  position: relative;
  display: block;
  height: 61px;
  margin-bottom: 10px;
  border: 2px solid #CCCCC6;
  border-radius: 3px; }

.overlay-icon {
  position: absolute;
  top: 0;
  width: 100%;
  height: 61px;
  background: #AC0000;
  border-radius: 3px;
  color: #FFF;
  font-size: 20px;
  line-height: 0;
  display: block;
  opacity: 0;
  -webkit-transition: 300ms;
  -moz-transition: 300ms;
  -o-transition: 300ms;
  transition: 300ms; }

.overlay-icon i {
  position: relative;
  top: 50%;
  margin-top: -20px; }

.video-gallery a:hover .overlay-icon {
  opacity: 1; }

.video-gallery p {
  color: #8C8C88;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0px; }

.video-gallery h2 {
  color: #8c8c88;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 300;
  text-transform: uppercase;
  margin-top: 0px; }

.address {
  margin-top: 30px;
  position: relative;
  overflow: hidden; }

.address img {
  width: 100%; }

.address p {
  color: #666663;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 300;
  left: 25px;
  position: absolute;
  top: 50px; }

.footer-widget {
  margin-bottom: 68px; }

.footer-widget .container {
  border-top: 1px solid #FFFFFF;
  padding-top: 15px; }

.single-widget h2 {
  color: #666663;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 22px;
  text-transform: uppercase; }

.single-widget h2 i {
  margin-right: 15px; }

.single-widget ul li a {
  color: #8C8C88;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 300;
  padding: 5px 0; }

.single-widget ul li a i {
  margin-right: 18px; }

.single-widget ul li a:hover {
  background: none;
  color: #AC0000; }

.searchform input {
  border: 1px solid #DDDDDD;
  color: #CCCCC6;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  margin-top: 0;
  outline: medium none;
  padding: 7px;
  width: 212px; }

.searchform button {
  background: #AC0000;
  border: medium none;
  border-radius: 0;
  margin-left: -5px;
  margin-top: -3px;
  padding: 7px 17px; }

.searchform button i {
  color: #FFFFFF;
  font-size: 20px; }

.searchform button:hover,
.searchform button:focus {
  background-color: #AC0000; }

.searchform p {
  color: #8C8C88;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 300;
  margin-top: 25px; }

.footer-bottom {
  background: #D6D6D0;
  padding-top: 10px; }

.footer-bottom p {
  color: #363432;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  margin-left: 15px; }

.footer-bottom p span a {
  color: #AC0000;
  font-style: italic;
  text-decoration: underline; }

/*************************
******* Home ******
**************************/
#slider {
  padding-bottom: 45px; }

.carousel-indicators li {
  background: #C4C4BE; }

.carousel-indicators li.active {
  background: #AC0000; }

.item {
  padding-left: 100px; }

.pricing {
  position: absolute;
  right: 40%;
  top: 52%; }

.girl {
  margin-left: 0; }

.item h1 {
  color: #B4B1AB;
  font-family: abel;
  font-size: 48px;
  margin-top: 115px; }

.item h1 span {
  color: #AC0000; }

.item h2 {
  color: #363432;
  font-family: 'Roboto', sans-serif;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 22px;
  margin-top: 10px; }

.item p {
  color: #363432;
  font-size: 16px;
  font-weight: 300;
  font-family: 'Roboto', sans-serif; }

.get {
  background: #AC0000;
  border: 0 none;
  border-radius: 0;
  color: #FFFFFF;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 300;
  margin-top: 23px; }

.item button:hover {
  background: #AC0000; }

.control-carousel {
  position: absolute;
  top: 50%;
  font-size: 60px;
  color: #C2C2C1; }

.control-carousel:hover {
  color: #AC0000; }

.right {
  right: 0; }

.category-products {
  border: 1px solid #F7F7F0;
  margin-bottom: 35px;
  padding-bottom: 20px;
  padding-top: 15px; }

.left-sidebar h2, .brands_products h2 {
  color: #AC0000;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 700;
  margin: 0 auto 30px;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  z-index: 3; }

.left-sidebar h2:after, h2.title:after {
  content: " ";
  position: absolute;
  border: 1px solid #f5f5f5;
  bottom: 8px;
  left: 0;
  width: 100%;
  height: 0;
  z-index: -2; }

.left-sidebar h2:before {
  content: " ";
  position: absolute;
  background: #fff;
  bottom: -6px;
  width: 130px;
  height: 30px;
  z-index: -1;
  left: 50%;
  margin-left: -65px; }

h1.title:before {
  content: " ";
  position: absolute;
  background: #fff;
  bottom: -6px;
  width: 220px;
  height: 30px;
  z-index: -1;
  left: 50%;
  margin-left: -110px; }

h2.title:before {
  content: " ";
  position: absolute;
  background: #fff;
  bottom: -6px;
  width: 220px;
  height: 30px;
  z-index: -1;
  left: 50%;
  margin-left: -110px; }

.category-products .panel {
  background-color: #FFFFFF;
  border: 0px;
  border-radius: 0px;
  box-shadow: none;
  margin-bottom: 0px; }

.category-products .panel-default .panel-heading {
  background-color: #FFFFFF;
  border: 0 none;
  color: #FFFFFF;
  padding: 5px 20px; }

.category-products .panel-default .panel-heading .panel-title a {
  color: #696763;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase; }

.panel-group .panel-heading + .panel-collapse .panel-body {
  border-top: 0 none; }

.category-products .badge {
  background: none;
  border-radius: 10px;
  color: #696763;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  min-width: 10px;
  padding: 3px 7px;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap; }

.panel-body ul {
  padding-left: 20px; }

.panel-body ul li a {
  color: #696763;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  text-transform: uppercase; }

.brands-name {
  border: 1px solid #F7F7F0;
  padding-bottom: 20px;
  padding-top: 15px; }

.brands-name .nav-stacked li a {
  background-color: #FFFFFF;
  color: #696763;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  padding: 5px 25px;
  text-decoration: none;
  text-transform: uppercase; }

.brands-name .nav-stacked li a:hover {
  background-color: #fff;
  color: #696763; }

.shipping {
  background-color: #F2F2F2;
  margin-top: 40px;
  overflow: hidden;
  padding-top: 20px;
  position: relative; }

.price-range {
  margin-top: 30px; }

.well {
  background-color: #FFFFFF;
  border: 1px solid #F7F7F0;
  border-radius: 4px;
  box-shadow: none;
  margin-bottom: 20px;
  min-height: 20px;
  padding: 35px; }

.tooltip-inner {
  background-color: #AC0000;
  border-radius: 4px;
  color: #FFFFFF;
  max-width: 200px;
  padding: 3px 8px;
  text-align: center;
  text-decoration: none; }

.tooltip.top .tooltip-arrow {
  border-top-color: #AC0000;
  border-width: 5px 5px 0;
  bottom: 0;
  left: 50%;
  margin-left: -5px; }

.padding-right {
  padding-right: 0; }

.features_items {
  overflow: hidden; }

h1.title {
  color: #AC0000;
  font-family: 'Roboto', sans-serif;
  font-size: 30px;
  font-weight: 700;
  margin: 0 15px;
  text-transform: uppercase;
  margin-bottom: 30px;
  position: relative; }

h2.title {
  color: #AC0000;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 700;
  margin: 0 15px;
  text-transform: uppercase;
  margin-bottom: 30px;
  position: relative; }

.product-image-wrapper {
  border: 1px solid #F7F7F5;
  overflow: hidden;
  margin-bottom: 30px; }

.single-products {
  position: relative; }

.new, .sale {
  position: absolute;
  top: 0;
  right: 0; }

.productinfo h2 {
  color: #AC0000;
  height: 2em;
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  font-weight: 700; }

.product-overlay h2 {
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  font-weight: 700; }

div.diamond a.add-to-cart {
  background-image: url(../../images/diamond-lamp.png);
  background-size: auto 90%;
  background-repeat: no-repeat;
  background-position: right center; }

div.smart-lamp a.add-to-cart {
  background-image: url(../../images/smart-lamp.png);
  background-repeat: no-repeat;
  background-position: right center;
  background-size: auto 65%; }

div.philips a.add-to-cart {
  background-image: url(../../images/philips.png);
  background-repeat: no-repeat;
  background-position: right center;
  background-size: auto 90%; }

div.oem a.add-to-cart {
  background-image: url(../../images/oem.png);
  background-repeat: no-repeat;
  background-position: right center;
  background-size: auto 90%; }

.productinfo p {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #696763; }

.productinfo img {
  width: 100%; }

.productinfo {
  position: relative; }

.product-overlay {
  background: #AC0000;
  top: 0;
  display: none;
  height: 0;
  position: absolute;
  transition: height 500ms ease 0s;
  width: 100%;
  display: block; }

.single-products:hover .product-overlay {
  display: block;
  height: 100%; }

.product-overlay .overlay-content {
  bottom: 0;
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%; }

.product-overlay .add-to-cart {
  background: #fff;
  border: 0 none;
  border-radius: 0;
  color: #AC0000;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  margin-bottom: 25px; }

.product-overlay .add-to-cart:hover {
  background: #fff;
  color: #AC0000; }

.product-overlay p {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #fff; }

.add-to-cart {
  background: #F5F5ED;
  border: 0 none;
  border-radius: 0;
  color: #696763;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  margin-bottom: 25px; }

.add-to-cart:hover {
  background: #AC0000;
  border: 0 none;
  border-radius: 0;
  color: #FFFFFF; }

.add-to {
  margin-bottom: 10px; }

.add-to-cart i {
  margin-right: 5px; }

.add-to-cart:hover {
  background: #AC0000;
  color: #FFFFFF; }

.choose {
  border-top: 1px solid #F7F7F0; }

.choose ul li a {
  color: #B3AFA8;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  padding-left: 0;
  padding-right: 0; }

.choose ul li a i {
  margin-right: 5px; }

.choose ul li a:hover {
  background: none;
  color: #AC0000; }

.category-tab {
  overflow: hidden; }

.category-tab ul {
  background: #40403E;
  border-bottom: 1px solid #AC0000;
  list-style: none outside none;
  margin: 0 0 30px;
  padding: 0;
  width: 100%; }

.category-tab ul li a {
  border: 0 none;
  border-radius: 0;
  color: #B3AFA8;
  display: block;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  text-transform: uppercase; }

.category-tab ul li a:hover {
  background: #AC0000;
  color: #fff; }

.nav-tabs li.active a, .nav-tabs li.active a:hover, .nav-tabs li.active a:focus {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background-color: #AC0000;
  border: 0px;
  color: #FFFFFF;
  cursor: default;
  margin-right: 0;
  margin-left: 0; }

.nav-tabs li a {
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
  line-height: 1.42857;
  margin-right: 0; }

.recommended_items {
  overflow: hidden; }

#recommended-item-carousel .carousel-inner .item {
  padding-left: 0; }

.recommended-item-control {
  position: absolute;
  top: 41%; }

.recommended-item-control i {
  background: none repeat scroll 0 0 #AC0000;
  color: #FFFFFF;
  font-size: 20px;
  padding: 4px 10px; }

.recommended-item-control i:hover {
  background: #ccccc6; }

.our_partners {
  overflow: hidden; }

.our_partners ul {
  background: #F7F7F0;
  margin-bottom: 50px; }

.our_partners ul li a:hover {
  background: none; }

/*************************
*******Shop CSS******
**************************/
#advertisement {
  padding-bottom: 45px; }

#advertisement img {
  width: 100%; }

.pagination {
  display: inline-block;
  margin-bottom: 25px;
  margin-top: 0;
  padding-left: 15px; }

.pagination li:first-child a, .pagination li:first-child span {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin-left: 0; }

.pagination li:last-child a, .pagination li:last-child span {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0; }

.pagination .active a, .pagination .active span, .pagination .active a:hover, .pagination .active span:hover, .pagination .active a:focus, .pagination .active span:focus {
  background-color: #AC0000;
  border-color: #AC0000;
  color: #FFFFFF;
  cursor: default;
  z-index: 2; }

.pagination li a, .pagination li span {
  background-color: #f0f0e9;
  border: 0;
  float: left;
  line-height: 1.42857;
  margin-left: -1px;
  padding: 6px 12px;
  position: relative;
  text-decoration: none;
  margin-right: 5px;
  color: #000; }

.pagination li a:hover {
  background: #AC0000;
  color: #fff; }

/*************************
*******Product Details CSS******
**************************/
.product-details {
  overflow: hidden; }

#similar-product {
  margin-top: 40px; }

#reviews {
  padding-left: 25px;
  padding-right: 25px; }

.product-details {
  margin-bottom: 40px;
  overflow: hidden;
  margin-top: 10px; }

.view-product {
  position: relative; }

.view-product img {
  border: 1px solid #F7F7F0;
  height: 380px;
  width: 100%; }

.view-product h3 {
  background: #AC0000;
  bottom: 0;
  color: #FFFFFF;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0;
  padding: 8px 20px;
  position: absolute;
  right: 0; }

#similar-product .carousel-inner .item {
  padding-left: 0px; }

#similar-product .carousel-inner .item img {
  display: inline-block;
  margin-left: 15px; }

.item-control {
  position: absolute;
  top: 35%; }

.item-control i {
  background: #AC0000;
  color: #FFFFFF;
  font-size: 20px;
  padding: 5px 10px; }

.item-control i:hover {
  background: #ccccc6; }

.product-information {
  overflow: hidden;
  position: relative; }

.newarrival {
  position: absolute;
  top: 0;
  left: 0; }

.product-information h2 {
  color: #363432;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  margin-top: 0; }

.product-information p {
  color: #696763;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 5px; }

.product-information span {
  display: inline-block;
  margin-bottom: 8px;
  margin-top: 18px; }

.product-information span span {
  color: #AC0000;
  float: left;
  font-family: 'Roboto', sans-serif;
  font-size: 30px;
  font-weight: 700;
  margin-right: 20px;
  margin-top: 0px; }

.product-information span input {
  border: 1px solid #DEDEDC;
  color: #696763;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 700;
  height: 33px;
  outline: medium none;
  text-align: center;
  width: 50px; }

.product-information span label {
  color: #696763;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  margin-right: 5px; }

.share {
  margin-top: 15px; }

.cart {
  background: #AC0000;
  border: 0 none;
  border-radius: 0;
  color: #FFFFFF;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  margin-bottom: 10px;
  margin-left: 20px; }

.shop-details-tab {
  border: 1px solid #F7F7F0;
  margin-bottom: 75px;
  margin-left: 15px;
  margin-right: 15px;
  padding-bottom: 10px; }

.shop-details-tab .col-sm-12 {
  padding-left: 0;
  padding-right: 0; }

#reviews ul {
  background: #FFFFFF;
  border: 0 none;
  list-style: none outside none;
  margin: 0 0 20px;
  padding: 0; }

#reviews ul li {
  display: inline-block; }

#reviews ul li a {
  color: #696763;
  display: block;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  padding-right: 15px; }

#reviews ul li a i {
  color: #AC0000;
  padding-right: 8px; }

#reviews ul li a:hover {
  background: #fff;
  color: #AC0000; }

#reviews p {
  color: #363432; }

#reviews form span {
  display: block; }

#reviews form span input {
  background: #F0F0E9;
  border: 0 none;
  color: #A6A6A1;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  outline: medium none;
  padding: 8px;
  width: 48%; }

#reviews form span input:last-child {
  margin-left: 3%; }

#reviews textarea {
  background: #F0F0E9;
  border: medium none;
  color: #A6A6A1;
  height: 195px;
  margin-bottom: 25px;
  margin-top: 15px;
  outline: medium none;
  padding-left: 10px;
  padding-top: 15px;
  resize: none;
  width: 99.5%; }

#reviews button {
  background: #AC0000;
  border: 0 none;
  border-radius: 0;
  color: #FFFFFF;
  font-family: 'Roboto', sans-serif;
  font-size: 14px; }

/*************************
*******404 CSS******
**************************/
.logo-404 {
  margin-top: 60px; }

.content-404 h1 {
  color: #363432;
  font-family: 'Roboto', sans-serif;
  font-size: 41px;
  font-weight: 300; }

.content-404 img {
  margin: 0 auto; }

.content-404 p {
  color: #363432;
  font-family: 'Roboto', sans-serif;
  font-size: 18px; }

.content-404 h2 {
  margin-top: 50px; }

.content-404 h2 a {
  background: #AC0000;
  color: #FFFFFF;
  font-family: 'Roboto', sans-serif;
  font-size: 44px;
  font-weight: 300;
  padding: 8px 40px; }

/*************************
*******login page CSS******
**************************/
#form {
  display: block;
  margin-bottom: 185px;
  margin-top: 185px;
  overflow: hidden; }

.login-form h2, .signup-form h2 {
  color: #696763;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 30px; }

.login-form form input, .signup-form form input {
  background: #F0F0E9;
  border: medium none;
  color: #696763;
  display: block;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 300;
  height: 40px;
  margin-bottom: 10px;
  outline: medium none;
  padding-left: 10px;
  width: 100%; }

.login-form form span {
  line-height: 25px; }

.login-form form span input {
  width: 15px;
  float: left;
  height: 15px;
  margin-right: 5px; }

.login-form form button {
  margin-top: 23px; }

.login-form form button, .signup-form form button {
  background: #AC0000;
  border: medium none;
  border-radius: 0;
  color: #FFFFFF;
  display: block;
  font-family: 'Roboto', sans-serif;
  padding: 6px 25px; }

.login-form label input {
  border: medium none;
  display: inline-block;
  height: 0;
  margin-bottom: 0;
  outline: medium none;
  padding-left: 0; }

.or {
  background: #AC0000;
  border-radius: 40px;
  color: #FFFFFF;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  height: 50px;
  line-height: 50px;
  margin-top: 75px;
  text-align: center;
  width: 50px; }

/*************************
*******Cart CSS******
**************************/
#do_action {
  margin-bottom: 50px; }

.breadcrumbs {
  position: relative; }

.breadcrumbs .breadcrumb {
  background: transparent;
  margin-bottom: 75px;
  padding-left: 0; }

.breadcrumbs .breadcrumb li a {
  background: #AC0000;
  color: #FFFFFF;
  padding: 3px 7px; }

.breadcrumbs .breadcrumb li a:after {
  content: "";
  height: auto;
  width: auto;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent transparent #AC0000;
  position: absolute;
  top: 11px;
  left: 48px; }

.breadcrumbs .breadcrumb > li + li:before {
  content: " "; }

#cart_items .cart_info {
  border: 1px solid #E6E4DF;
  margin-bottom: 50px;
  margin-top: 30px; }

#cart_items .cart_info .cart_menu {
  background: #AC0000;
  color: #fff;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  font-weight: normal; }

#cart_items .cart_info .table.table-condensed thead tr {
  height: 51px; }

#cart_items .cart_info .table.table-condensed tr {
  border-bottom: 1px solid #F7F7F0; }

#cart_items .cart_info .table.table-condensed tr:last-child {
  border-bottom: 0; }

.cart_info table tr td {
  border-top: 0 none;
  vertical-align: inherit; }

#cart_items .cart_info .image {
  padding-left: 30px; }

#cart_items .cart_info .cart_description h4 {
  margin-bottom: 0; }

#cart_items .cart_info .cart_description h4 a {
  color: #363432;
  font-family: arial, helvetica,sans-serif;
  font-size: 20px;
  font-weight: normal; }

#cart_items .cart_info .cart_description p {
  color: #696763; }

#cart_items .cart_info .cart_price p {
  color: #696763;
  font-size: 18px; }

#cart_items .cart_info .cart_total_price {
  color: #AC0000;
  font-size: 24px; }

.cart_product {
  display: block;
  margin: 15px -70px 10px 25px; }

.cart_quantity_button a {
  background: #F0F0E9;
  color: #696763;
  display: inline-block;
  font-size: 16px;
  height: 28px;
  overflow: hidden;
  text-align: center;
  width: 35px;
  float: left; }

.cart_quantity_input {
  color: #696763;
  float: left;
  font-size: 16px;
  text-align: center;
  font-family: arial, helvetica,sans-serif; }

.cart_delete {
  display: block;
  overflow: hidden; }

.cart_delete a {
  background: #F0F0E9;
  color: #FFFFFF;
  padding: 5px 7px;
  font-size: 16px; }

.cart_delete a:hover {
  background: #AC0000; }

.bg h1.title {
  margin-right: 0;
  margin-left: 0;
  margin-top: 30px; }

.bg h2.title {
  margin-right: 0;
  margin-left: 0;
  margin-top: 30px; }

.heading h3 {
  color: #363432;
  font-size: 20px;
  font-family: 'Roboto', sans-serif; }

.heading p {
  color: #434343;
  font-size: 16px;
  font-weight: 300; }

#do_action .total_area {
  padding-bottom: 18px !important; }

#do_action .total_area, #do_action .chose_area {
  border: 1px solid #E6E4DF;
  color: #696763;
  padding: 30px 40px 30px 0;
  margin-bottom: 80px; }

.total_area span {
  float: right; }

.total_area ul li {
  background: #E6E4DF;
  color: #696763;
  margin-top: 10px;
  padding: 7px 20px; }

.user_option label {
  color: #696763;
  font-weight: normal;
  margin-left: 10px; }

.user_info {
  display: block;
  margin-bottom: 15px;
  margin-top: 20px;
  overflow: hidden; }

.user_info label {
  color: #696763;
  display: block;
  font-size: 15px;
  font-weight: normal; }

.user_info .single_field {
  width: 31%; }

.user_info .single_field.zip-field input {
  background: transparent;
  border: 1px solid #F0F0E9; }

.user_info > li {
  float: left;
  margin-right: 10px; }

.user_info input, select, textarea {
  background: #F0F0E9;
  border: 0;
  color: #696763;
  padding: 5px;
  width: 100%;
  border-radius: 0;
  resize: none; }

.user_info select:focus {
  border: 0; }

.chose_area .update {
  margin-left: 40px; }

.update, .check_out {
  background: #AC0000;
  border-radius: 0;
  color: #FFFFFF;
  margin-top: 18px;
  border: none;
  padding: 5px 15px; }

.update {
  margin-left: 40px; }

.check_out {
  margin-left: 20px; }

/*************************
*******checkout CSS******
**************************/
.step-one {
  margin-bottom: -10px; }

.register-req, .step-one .heading {
  background: none repeat scroll 0 0 #F0F0E9;
  color: #363432;
  font-size: 20px;
  margin-bottom: 35px;
  padding: 10px 25px;
  font-family: 'Roboto', sans-serif; }

.checkout-options {
  padding-left: 20px; }

.checkout-options h3 {
  color: #363432;
  font-size: 20px;
  margin-bottom: 0;
  font-weight: normal;
  font-family: 'Roboto', sans-serif; }

.checkout-options p {
  color: #434343;
  font-weight: 300;
  margin-bottom: 25px; }

.checkout-options .nav li {
  float: left;
  margin-right: 45px;
  color: #696763;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  font-weight: normal; }

.checkout-options .nav label {
  font-weight: normal; }

.checkout-options .nav li a {
  color: #AC0000;
  font-size: 18px;
  font-weight: normal;
  padding: 0; }

.checkout-options .nav li a:hover {
  background: inherit; }

.checkout-options .nav i {
  margin-right: 10px;
  border-radius: 50%;
  padding: 5px;
  background: #AC0000;
  color: #fff;
  font-size: 14px;
  padding: 2px 3px; }

.register-req {
  font-size: 14px;
  font-weight: 300;
  padding: 15px 20px;
  margin-top: 35px; }

.register-req p {
  margin-bottom: 0; }

.shopper-info p,
.bill-to p,
.order-message p {
  color: #696763;
  font-size: 20px;
  font-weight: 300; }

.shopper-info .btn-primary {
  background: #AC0000;
  border: 0 none;
  border-radius: 0;
  margin-right: 15px;
  margin-top: 20px; }

.form-two, .form-one {
  float: left;
  width: 47%; }

.shopper-info > form > input,
.form-two > form > select,
.form-two > form > input,
.form-one > form > input {
  background: #F0F0E9;
  border: 0 none;
  margin-bottom: 10px;
  padding: 10px;
  width: 100%;
  font-weight: 300; }

.form-two > form > select {
  padding: 10px 5px; }

.form-two {
  margin-left: 5%; }

.order-message textarea {
  font-size: 12px;
  height: 335px;
  margin-bottom: 20px;
  padding: 15px 20px; }

.order-message label {
  font-weight: 300;
  color: #696763;
  font-family: 'Roboto', sans-serif;
  margin-left: 10px;
  font-size: 14px; }

.review-payment h2 {
  color: #696763;
  font-size: 20px;
  font-weight: 300;
  margin-top: 45px;
  margin-bottom: 20px; }

.payment-options {
  margin-bottom: 125px;
  margin-top: -25px; }

.payment-options span label {
  color: #696763;
  font-size: 14px;
  font-weight: 300;
  margin-right: 30px; }

#cart_items .cart_info
.table.table-condensed.total-result {
  margin-bottom: 10px;
  margin-top: 35px;
  color: #696763; }

#cart_items .cart_info
.table.table-condensed.total-result tr {
  border-bottom: 0; }

#cart_items .cart_info
.table.table-condensed.total-result span {
  color: #AC0000;
  font-weight: 700;
  font-size: 16px; }

#cart_items .cart_info
.table.table-condensed.total-result
.shipping-cost {
  border-bottom: 1px solid #F7F7F0; }

/*************************
*******Blog CSS******
**************************/
.blog-post-area
.single-blog-post h3 {
  color: #696763;
  font-size: 16px;
  font-family: arial, helvetica,sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 17px; }

.blog-post-area
.single-blog-post a img {
  border: 1px solid #F7F7F0;
  width: 100%;
  margin-bottom: 30px; }

.blog-post-area
.single-blog-post p {
  color: #363432; }

.blog-post-area
.post-meta {
  display: block;
  margin-bottom: 25px;
  overflow: hidden; }

.blog-post-area
.post-meta ul {
  padding: 0;
  display: inline; }

.blog-post-area
.post-meta ul li {
  background: #F0F0E9;
  float: left;
  margin-right: 10px;
  padding: 0 5px;
  font-size: 11px;
  color: #393b3b;
  position: relative; }

.blog-post-area
.post-meta ul li i {
  background: #AC0000;
  color: #FFFFFF;
  margin-left: -4px;
  margin-right: 7px;
  padding: 4px 7px; }

.sinlge-post-meta li i:after,
.blog-post-area
.post-meta ul li i:after {
  content: "";
  position: absolute;
  width: auto;
  height: auto;
  border-color: transparent transparent transparent #AC0000;
  border-width: 4px;
  border-style: solid;
  top: 6px;
  left: 24px; }

.blog-post-area
.post-meta ul span {
  float: right;
  color: #AC0000; }

.post-meta span {
  float: right; }

.post-meta span i {
  color: #AC0000; }

.blog-post-area
.single-blog-post
.btn-primary {
  background: #AC0000;
  border: medium none;
  border-radius: 0;
  color: #FFFFFF;
  margin-top: 17px; }

.pagination-area {
  margin-bottom: 45px;
  margin-top: 45px; }

.pagination-area
.pagination li a {
  background: #F0F0E9;
  border: 0 none;
  border-radius: 0;
  color: #696763;
  margin-right: 5px;
  padding: 4px 12px; }

.pagination-area
.pagination li a:hover,
.pagination-area
.pagination li .active {
  background: #AC0000;
  color: #fff; }

/*************************
*******Blog Single CSS******
**************************/
.pager-area {
  overflow: hidden; }

.pager-area .pager li a {
  background: #F0F0E9;
  border: 0 none;
  border-radius: 0;
  color: #696763;
  font-size: 12px;
  font-weight: 700;
  padding: 4px;
  text-transform: uppercase;
  width: 57px; }

.pager-area
.pager li a:hover {
  background: #AC0000;
  color: #fff; }

.rating-area {
  border: 1px solid #F7F7F0;
  direction: block;
  overflow: hidden; }

.rating-area ul li {
  float: left;
  padding: 5px;
  font-size: 12px; }

.rating-area .ratings {
  float: left;
  padding-left: 0;
  margin-bottom: 0; }

.rating-area
.ratings li i {
  color: #CCCCCC; }

.rating-area .rate-this {
  color: #363432;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase; }

.rating-area
.ratings .color,
.rating-area .color {
  color: #AC0000; }

.rating-area .tag {
  float: right;
  margin-bottom: 0;
  margin-right: 10px; }

.rating-area .tag li {
  padding: 5px 2px; }

.rating-area .tag li span {
  color: #363432; }

.socials-share {
  margin-bottom: 30px;
  margin-top: 18px; }

.commnets
.media-object {
  margin-right: 15px;
  width: 100%; }

.commnets {
  border: 1px solid #F7F7F0;
  padding: 18px 18px 18px 0;
  margin-bottom: 50px; }

.commnets .pull-left {
  margin-right: 22px; }

.commnets p,
.response-area p,
.replay-box p {
  font-size: 12px; }

.media-heading {
  color: #363432;
  font-size: 14px;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 15px; }

.blog-socials {
  margin-bottom: -9px;
  margin-top: 14px; }

.blog-socials ul {
  padding-left: 0;
  overflow: hidden;
  float: left; }

.blog-socials .btn.btn-primary {
  margin-top: 0; }

.blog-socials ul li {
  float: left;
  height: 17px;
  margin-right: 5px;
  text-align: center;
  width: 17px; }

.blog-socials ul li a {
  color: #393B3B;
  display: block;
  font-size: 10px;
  padding: 1px;
  background: #F0F0E9; }

.blog-socials ul li a:hover {
  color: #fff;
  background: #AC0000; }

.media-list .btn-primary,
.commnets .btn-primary {
  background: #FC9A11;
  border: 0 none;
  border-radius: 0;
  color: #FFFFFF;
  float: left;
  font-size: 10px;
  padding: 1px 7px;
  text-transform: uppercase; }

.response-area h2 {
  color: #363432;
  font-size: 20px;
  font-weight: 700; }

.response-area .media {
  border: 1px solid #F7F7F0;
  padding: 18px 18px 18px 0;
  margin-bottom: 27px; }

.response-area .media img {
  height: 102px;
  width: 100%; }

.response-area .media .pull-left {
  margin-right: 25px; }

.response-area .second-media {
  margin-left: 5%;
  width: 95%; }

.sinlge-post-meta {
  overflow: hidden;
  padding-left: 0;
  margin-bottom: 15px; }

.sinlge-post-meta li {
  background: #F0F0E9;
  color: #363432;
  float: left;
  font-size: 10px;
  font-weight: 700;
  margin-right: 10px;
  padding: 0 10px 0 0;
  position: relative;
  text-transform: uppercase; }

.sinlge-post-meta li i {
  background: #AC0000;
  color: #FFFFFF;
  margin-right: 10px;
  padding: 8px 10px; }

.sinlge-post-meta li i:after {
  top: 7px;
  border-width: 6px;
  left: 27px; }

.replay-box {
  margin-bottom: 107px;
  margin-top: 55px; }

.replay-box h2 {
  font-weight: 700;
  font-size: 20px;
  color: #363432;
  margin-top: 0;
  margin-bottom: 45px; }

.replay-box label {
  background: #AC0000;
  color: #FFFFFF;
  margin-bottom: 15px;
  padding: 3px 15px;
  float: left;
  font-weight: 400; }

.replay-box span {
  color: #AC0000;
  float: right;
  font-weight: 700;
  margin-top: 21px; }

.replay-box form input {
  border: 1px solid #F7F7F0;
  color: #ADB2B2;
  font-size: 12px;
  margin-bottom: 22px;
  padding: 8px;
  width: 100%; }

.replay-box form input:hover,
.text-area textarea:hover {
  border: 1px solid #AC0000; }

.text-area {
  margin-top: 66px; }

.text-area textarea {
  background: transparent;
  border: 1px solid #F7F7F0; }

.btn.btn-primary {
  background: #AC0000;
  border: 0 none;
  border-radius: 0;
  margin-top: 16px; }

.blank-arrow {
  position: relative; }

.blank-arrow label:after {
  content: "";
  position: absolute;
  width: auto;
  height: auto;
  border-style: solid;
  border-width: 8px;
  border-color: #AC0000 transparent transparent transparent;
  top: 25px;
  left: 5px; }

/*************************
******* Contact CSS ********
**************************/
.contact-map {
  width: 100%;
  height: 385px;
  margin-bottom: 70px; }

.contact-info .heading,
.contact-form .heading {
  text-transform: capitalize; }

.contact-form .form-group {
  margin-bottom: 20px; }

#contact-page
.form-control::-moz-placeholder {
  color: #8D8D8D; }

#contact-page .form-control {
  background-color: #fff;
  border: 1px solid #ddd;
  color: #696763;
  height: 46px;
  padding: 6px 12px;
  width: 100%;
  font-size: 16px;
  border-radius: 4px;
  box-shadow: inherit; }

#contact-page #message {
  height: 160px;
  resize: none; }

#main-contact-form .btn-primary {
  margin-bottom: 15px;
  margin-top: 20px; }

#contact-page .form-control:focus,
#contact-page .form-control:hover {
  box-shadow: inherit;
  border-color: #FDB45E; }

#contact-page .contact-info {
  padding: 0 20px; }

#contact-page .contact-info address {
  margin-bottom: 40px;
  margin-top: -5px; }

#contact-page .contact-info p {
  margin-bottom: 0;
  color: #696763;
  font-size: 16px;
  line-height: 25px; }

.social-networks {
  overflow: hidden;
  text-align: center; }

.social-networks ul {
  margin-top: -5px;
  padding: 0;
  display: inline-block; }

.social-networks ul li {
  float: left;
  text-decoration: none;
  list-style: none;
  margin-right: 20px; }

.social-networks ul li:last-child {
  margin-right: 0; }

.social-networks ul li a {
  color: #999;
  font-size: 25px; }

.contact-info .social-networks ul li a i {
  background: none; }

.contact-info .social-networks ul li a:hover {
  color: #AC0000; }

section .container .features_items {
  margin-top: 30px; }

div.product-information .diamond {
  height: 60px;
  background-image: url(../../images/diamond-lamp.png);
  background-size: auto 90%;
  background-repeat: no-repeat;
  background-position: top right; }

div.product-information .smart-lamp {
  height: 60px;
  background-image: url(../../images/smart-lamp.png);
  background-repeat: no-repeat;
  background-position: top right;
  background-size: auto 65%; }

div.product-information .oem {
  height: 60px;
  background-image: url(../../images/oem.png);
  background-size: auto 90%;
  background-repeat: no-repeat;
  background-position: top right; }

div.product-information .philips {
  height: 60px;
  background-image: url(../../images/philips.png);
  background-repeat: no-repeat;
  background-position: top right;
  background-size: auto 65%; }

div.contact {
  float: left; }

h1.title::after {
  border: 1px solid #f5f5f5;
  bottom: 14px;
  content: " ";
  height: 0;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: -2; }
